import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {ROOM_MOCK} from '../../MockData';
import Carousel from '../../Components/SmallCarousel';
import withPageEntry from '../../withPageEntry';

let BABY_MOCK = [
  '../../images/嬰兒室1.jpg',
  '../../images/嬰兒室2.jpg',
  '../../images/嬰兒室3.jpg',
  '../../images/嬰兒室4.jpg',
];

function Rooms(props) {
  const [dimension] = useOutlet('dimension');

  const [bannerDimension, setBannerDimension] = React.useState(null);
  const [selectedRoomTypeIdx, setSelectedRoomTypeIdx] = React.useState(0);

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = (elem && elem.getBoundingClientRect()) || 0;
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  let babyRoomImgWidth =
    dimension.rwd === 'desktop'
      ? (dimension.innerWidth - 220) / 4
      : (dimension.innerWidth - 40) / 2;
  let itemWidth = (dimension.innerWidth - 200 - 40) / 3;

  return (
    <Wrapper dimension={dimension} id="rev-RoomPage">
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          alt="room"
          src="/images/room-banner-1.png"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className="section">
        <div className="subtitle">Rooms</div>
        <div className="title">套房</div>
        <div className="pj-row" style={{padding: '20px 0px'}}>
          {ROOM_MOCK.map((room, idx) => (
            <div
              onClick={() => setSelectedRoomTypeIdx(idx)}
              className={`tab ${
                selectedRoomTypeIdx === idx ? 'selected' : ''
              }`}>
              <p className="content">{room.name}</p>
            </div>
          ))}
        </div>
        <div className="carousel-wrapper">
          <div style={{flex: 1}}>
            <Carousel
              width={
                dimension.rwd === 'desktop'
                  ? dimension?.innerWidth / 2
                  : dimension?.innerWidth - 40
              }
              height={
                dimension.rwd === 'desktop'
                  ? (dimension.innerWidth / 2) * 0.66
                  : dimension.innerWidth * 0.66
              }
              items={ROOM_MOCK[selectedRoomTypeIdx].imagesArray}
              // style={{position: 'absolute', top: 0, left: 0}}
            />
          </div>

          <div style={{flexBasis: 40}} />
          <div style={{flex: 1}}>
            <div className="title">{ROOM_MOCK[selectedRoomTypeIdx].name}</div>
            {ROOM_MOCK[selectedRoomTypeIdx].info.map((data, idx) => (
              <div>
                <div className="subtitle" style={{marginTop: 20}}>
                  {data.title}
                </div>
                <p className="content">{data.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="section-dark">
        <div className="subtitle">BABY ROOM</div>
        <div className="title">嬰兒室</div>
        <p
          className="content"
          style={{
            marginTop: 40,
          }}>{`新生兒們看似遵循標準作業程序的誕生，卻有著無法規格化的感動與體驗。
小嘴吧的呢喃、蜷曲的小拳頭、第一抹微微笑，對親子而言都是這趟生命旅程的獨一無二。

本館二樓專設為寶寶活動空間，並區分為3間獨立嬰兒室與親子互動區。
24小時的母嬰照護服務，並搭配一對一寶寶視訊鏡頭與全透明化嬰兒照護服務。
堅持高規格的感染控制搭配內坎式的紫外線消毒燈、高隔板與兩段式奶瓶消毒機，給予新生兒最完善的照護。`}</p>
        <div className="pj-row wrap" style={{marginTop: 40}}>
          {BABY_MOCK.map((img, idx) => (
            <img
              src={img}
              style={{
                width: babyRoomImgWidth,
                height: babyRoomImgWidth,
                objectFit: 'cover',
              }}
            />
          ))}
        </div>
      </section>

      <section className="section-dark">
        <div className="subtitle">DISCOVERY</div>
        <div className="title">角落探索</div>
        <div
          className="content"
          style={{
            marginTop: 30,
          }}>{`小王子曾經說過：『真正重要的東西，只用眼睛是看不見的』
青田悅是一棟量『生』打造的產後護理之家，一路從平地開始，每個環節都希望能做到極致
從Facebook 粉絲專頁開始，小編們就帶著大家 #探索青田悅 的用心，發掘那些只用眼睛可能看不見的真正重要的東西
如同我們邀請你來青田悅，我們陪你探索新的自己。

1. UV殺菌等級的BWT淨水系統
2. 溫柔又貼心的勿擾系統
3. 高規格的房間隔音技術-千鳥工法
4. 原裝進口飄洋過海的靜音電動床
5. 永續資源愛地球-電動車充電樁
6. 會降溫也具備高規格感控的冷氣機
`}</div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: var(--topNavBarHeight);

  & > .banner {
    width: 100%;
    height: 600px;
    /* position: relative; */
    /* background-color: var(--primaryColor); */
  }

  & .space-intro-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & .space-intro-item {
    margin-top: 30px;
    & .space-img {
      height: 250px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 20px;
    }
  }

`;

export default withPageEntry(Rooms);
